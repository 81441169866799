<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import axios from 'axios';

export default {
    data: function () {
		return {
            linkshell: []
		}
	},
	created: async function () {
		await this.fetchData()
	},
	methods: {
		fetchData: function () {
            const uri = process.env.VUE_APP_XIVAPI + '/linkshell/' + this.$route.params.lsid
            this.debugLevel('URI loaded: ' + uri)

			return axios.get(uri).then((response) => {
				this.linkshell = response.data
                document.title = response.data.name + ' | XIVDATA';
				this.debugLevel(['Linkshell data successfully loaded: ', this.linkshell])
			}).catch((error) => {
				this.debugLevel('Linkshell data failed to load with error: ' + error)
			})
		},

        debugLevel: function (value, level = 2) {
            const values = [];
            Array.isArray(value) ? value.forEach(element => values.push(element)) : values[0] = value;
            switch (level) {
                case 1:
                if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV == 'development') {
                    values.forEach(element => console.log(element));
                }
                break;
                case 2:
                if (process.env.NODE_ENV == 'development') {
                    values.forEach(element => console.log(element));
                  }
                break;
                default:
                    values.forEach(element => console.log(element));
                break;
            }
        },
	},
  page: {
    title: "FC Name",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout
  },
};
</script>
<template>
    <Layout>
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="page-title-box">
                    <h4 class="font-size-18">{{ linkshell.name }}</h4>
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">XIVDATA</li>
                        <li class="breadcrumb-item">{{ $t('messages.linkshell') }}</li>
                        <li class="breadcrumb-item active">{{ linkshell.name }}</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-xl-2">
                                <div class="card">
                                    <div class="nav flex-column nav-pills">
                                        <router-link :to="{path: '/linkshell/' + linkshell.id}" class="nav-link"><i class="fas fa-home"></i> {{ $t('freecompany.info_button') }}</router-link>
                                        <router-link :to="{path: '/linkshell/' + linkshell.id + '/member'}" class="nav-link"><i class="far fa-user"></i> {{ $t('freecompany.member_button') }}</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 col-lg-5 col-xl-9">
                                <h4>{{ linkshell.name }}</h4>
                                <hr />
                                MITGLIEDERLISTE
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>